import {
    BrowserRouter,
    Navigate,
    Route,
    Routes,
    useNavigate,
} from "react-router-dom";
import { ApiContextWrapper } from "./utils/ApiContext";
import "@mantine/core/styles.css";
import { createTheme, MantineProvider } from "@mantine/core";
import HomePage from "./Pages/Home/HomePage";
import LoginPage from "./Pages/Login/LoginPage";
import Signup from "./Pages/Signup/Signup";
import StudentSignUp from "./Pages/Signup/StudentSignUp";
import ProfessionalSignUp from "./Pages/Signup/ProfessionalSignUp";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Directory from "./Pages/Directory/Directory";
import Faqs from "./Pages/Faqs/Faqs";
import NewDashboard from "./Pages/Dashboard/newDashboard";
import PaymentSuccessPage from "./Pages/Stripe/paySuccess";
// import Pay from "./Pages/Stripe/pay";
import toast, { Toaster } from "react-hot-toast";
import UserDetail from "./Pages/Dashboard/userDetail";
import PaymentPage from "./Pages/Payment/PaymentPage";
import SampleHome from "./Pages/Home/SampleHome";
import Profile from "./Pages/Profile/Profile";
import SubscriptionRenewalPage from "./Pages/Payment/SubscriptionPayment";
import SubscriptionDetails from "./Pages/Subscription/subscription";
import Blogs from "./Pages/Blogs/blogs";
import View from "./Pages/Blogs/view";
import { OrderList } from "./Pages/OrderList/orderList";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Vlogs from "./Pages/Vlogs/vlogs";
import VlogView from "./Pages/Vlogs/view";
import EventView from "./Pages/Events/view";
import ViewMember from "./Pages/Directory/View";
import ResetPasswordPage from "./Pages/ResetPassword/resetPassword";
import ForgotPasswordPage from "./Pages/ForgotPassword/forgotPassword";
import PaymentCancelPage from "./Pages/Stripe/payCancel";
import OpportunitiesView from "./Pages/Opportunities/view";
import axios from "axios";
import { getLocalStorage, removeLocalStorage } from "./Helper/LocalStorage";
import { useState, useEffect, createContext } from "react";

const theme = createTheme({
    colors: {
        primary: ["#47c41a"],
        secondary: ["#039cc8"],
    },
    breakpoints: {
        xs: "30em",
        sm: "48em",
        md: "64em",
        lg: "74em",
        xl: "90em",
    },
});

// First, create an Auth Context
const AuthContext = createContext(null);

// Create Protected Route Component
const ProtectedRoute = ({ children }) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const validateAccess = async () => {
        try {
            const store = getLocalStorage("user");
            if (!store) {
                navigate("/login");
                return;
            }

            if (
                !sessionStorage.getItem("subsValidate") ||
                JSON.parse(sessionStorage.getItem("subsValidate")) !== 1
            ) {
                const response = await axios.get(
                    `${process.env.REACT_APP_BE_URI}/api/v1/common/verifysubs/${store.type}/${store.email}`
                );

                if (!response.data.active) {
                    toast.error("Your subscription is expired, please renew");
                    removeLocalStorage("user");
                    navigate("/login");
                    return;
                }

                if (response.data.renew) {
                    toast.success("login for the new subscription");
                    removeLocalStorage("user");
                    navigate("/login");
                    return;
                }

                sessionStorage.setItem("subsValidate", JSON.stringify(1));
                localStorage.setItem("globalSubsValidate", JSON.stringify(1));
                setIsAuthenticated(true);
            } else {
                setIsAuthenticated(true);
            }
        } catch (error) {
            toast.error(
                `Validation error: ${
                    error.response?.data?.message || error.message
                }`
            );
            navigate("/login");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        validateAccess();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>; // Replace with your loading component
    }

    return isAuthenticated ? children : null;
};

// Create Auth Provider
const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(getLocalStorage("user"));

    const value = {
        user,
        setUser,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

function App() {
    return (
        <AuthProvider>
            <ApiContextWrapper>
                <MantineProvider theme={theme}>
                    <BrowserRouter>
                        <Routes>
                            {/* Public Routes */}
                            <Route path="/login" element={<LoginPage />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route
                                path="/student-signup"
                                element={<StudentSignUp />}
                            />
                            <Route
                                path="/professional-signup"
                                element={<ProfessionalSignUp />}
                            />
                            <Route
                                path="/forgotpassword"
                                element={<ForgotPasswordPage />}
                            />
                            <Route
                                path="/resetpassword/:type/:token"
                                element={<ResetPasswordPage />}
                            />

                            {/* Payment Routes */}
                            <Route
                                path="/paySuccess"
                                element={<PaymentSuccessPage />}
                            />
                            <Route
                                path="/payCancel"
                                element={<PaymentCancelPage />}
                            />
                            <Route
                                path="/subscriptionpayment"
                                element={<PaymentPage />}
                            />
                            <Route
                                path="/renewalpayment"
                                element={<SubscriptionRenewalPage />}
                            />

                            {/* Protected Routes */}
                            <Route
                                path="/"
                                element={
                                    <ProtectedRoute>
                                        <HomePage />
                                    </ProtectedRoute>
                                }
                            >
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />
                                <Route
                                    path="/directory"
                                    element={<Directory />}
                                />
                                <Route
                                    path="/view_member"
                                    element={<ViewMember />}
                                />
                                <Route
                                    path="/subscription"
                                    element={<SubscriptionDetails />}
                                />
                                <Route path="/orders" element={<OrderList />} />
                                <Route path="/faqs" element={<Faqs />} />
                                <Route path="/blogs" element={<Blogs />} />
                                <Route path="/blog_view" element={<View />} />
                                <Route path="/vlogs" element={<Vlogs />} />
                                <Route
                                    path="/vlog_view"
                                    element={<VlogView />}
                                />
                                <Route
                                    path="/event_view"
                                    element={<EventView />}
                                />
                                <Route
                                    path="/opportunity_view"
                                    element={<OpportunitiesView />}
                                />
                                <Route path="/profile" element={<Profile />} />
                            </Route>

                            {/* Redirect unknown routes to dashboard or login */}
                            <Route
                                path="*"
                                element={
                                    <Navigate
                                        to={
                                            getLocalStorage("user")
                                                ? "/dashboard"
                                                : "/login"
                                        }
                                        replace
                                    />
                                }
                            />
                        </Routes>
                        <Toaster />
                    </BrowserRouter>
                </MantineProvider>
            </ApiContextWrapper>
        </AuthProvider>
    );
}
export default App;
