import {
    Text,
    Container,
    ActionIcon,
    Group,
    rem,
    Image,
    Affix,
} from "@mantine/core";
import {
    IconBrandTwitter,
    IconBrandYoutube,
    IconBrandInstagram,
} from "@tabler/icons-react";
import logo from "./logo-color.png";
import classes from "./Footer.module.css";

const data = [
    {
        title: "About",
        links: [
            { label: "Features", link: "#" },
            { label: "Pricing", link: "#" },
            { label: "Support", link: "#" },
            { label: "Forums", link: "#" },
        ],
    },
    {
        title: "Project",
        links: [
            { label: "Contribute", link: "#" },
            { label: "Media assets", link: "#" },
            { label: "Changelog", link: "#" },
            { label: "Releases", link: "#" },
        ],
    },
    {
        title: "Community",
        links: [
            { label: "Join Discord", link: "#" },
            { label: "Follow on Twitter", link: "#" },
            { label: "Email newsletter", link: "#" },
            { label: "GitHub discussions", link: "#" },
        ],
    },
];

const Footer = () => {
    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Text
                key={index}
                className={classes.link}
                component="a"
                href={link.link}
                onClick={(event) => event.preventDefault()}
                c="black"
            >
                {link.label}
            </Text>
        ));
        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <Image h={70} w={"auto"} src={logo} alt="pines"></Image>
                    <Text size="xs" className={classes.description}>
                        Lorem, ipsum dolor sit amet consectetur adipisicing
                        elit. Quisquam, ut commodi minus.
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter}>
                <Text size="sm">© 2024 PINES. All rights reserved.</Text>

                <Group
                    gap={0}
                    className={classes.social}
                    justify="flex-end"
                    wrap="nowrap"
                >
                    <ActionIcon size="lg" variant="subtle">
                        <IconBrandTwitter
                            style={{ width: rem(18), height: rem(18) }}
                            stroke={1.5}
                        />
                    </ActionIcon>
                    <ActionIcon size="lg" variant="subtle">
                        <IconBrandYoutube
                            style={{ width: rem(18), height: rem(18) }}
                            stroke={1.5}
                        />
                    </ActionIcon>
                    <ActionIcon size="lg" variant="subtle">
                        <IconBrandInstagram
                            style={{ width: rem(18), height: rem(18) }}
                            stroke={1.5}
                        />
                    </ActionIcon>
                </Group>
            </Container>
        </footer>
    );
};

export default Footer;
