import React from "react";
import { getLocalStorage } from "../../Helper/LocalStorage";
import {
    Avatar,
    Badge,
    Card,
    Center,
    Flex,
    Group,
    Paper,
    Text,
    Title,
} from "@mantine/core";
import {
    IconArrowBadgeRightFilled,
    IconCategory,
    IconMailFilled,
    IconMap2,
    IconUser,
} from "@tabler/icons-react";
import styles from "./Directory.module.css";
import { Link } from "react-router-dom";
export default function ViewMember() {
    const member = getLocalStorage("member");
    return (
        <Paper ta={"center"} p={50}>
            <Avatar size={250} mx={"auto"} src={member.profile} />
            <Text mt={20} tt={"capitalize"} fz={35} size="xl" fw={700}>
                {member.firstName} {member.lastName}
            </Text>
            <Flex align={"center"} justify={"center"} gap={10}>
                <Badge
                    className={styles.email_badge}
                    onClick={() => window.open(`mailto:${member.email}`)}
                    leftSection={<IconMailFilled size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"lowercase"}
                    size="xl"
                    fw={500}
                >
                    {member.email}
                </Badge>
                <Badge
                    leftSection={<IconCategory size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"capitalize"}
                    size="xl"
                    fw={500}
                >
                    {member.type}
                </Badge>
                <Badge
                    leftSection={<IconUser size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"capitalize"}
                    size="xl"
                    fw={500}
                >
                    {member.type === "professional"
                        ? member.profession
                        : member.course}
                </Badge>
                <Badge
                    leftSection={<IconMap2 size={18} />}
                    variant="gradient"
                    gradient={{ from: "cyan", to: "green", deg: 90 }}
                    mt={20}
                    tt={"capitalize"}
                    size="xl"
                    fw={500}
                >
                    {member.country}
                </Badge>
            </Flex>

            <Card shadow="sm" padding="lg" mt="md">
                <Title order={3} mb="md" fz={"md"} ta={"left"}>
                    Biography
                </Title>
                <Text mt={20} size="lg" fw={"normal"}>
                    {member.bio}
                </Text>
            </Card>

            <Card shadow="sm" padding="lg" mt="md" ta={"left"}>
                <Title order={3} mb="md" fz={"md"} ta={"left"}>
                    Additional Information
                </Title>
                <Paper my={5}>
                    <Center inline>
                        <IconArrowBadgeRightFilled style={{ margin: "5px" }} />
                        <Text size="lg" fw={700}>
                            What is the greatest benefit PINES membership can
                            offer you ?
                        </Text>
                    </Center>
                    <Text size="lg" fw={500} ml={35}>
                        {member.pinesBenefits ? member.pinesBenefits : "N/A"}
                    </Text>
                </Paper>

                <Paper my={5}>
                    <Center inline>
                        <IconArrowBadgeRightFilled style={{ margin: "5px" }} />
                        <Text size="lg" fw={700}>
                            How did you hear about PINES?
                        </Text>
                    </Center>
                    <Text size="lg" fw={500} ml={35}>
                        {member.hearPines ? member.hearPines : "N/A"}
                    </Text>
                </Paper>

                <Paper my={5}>
                    <Center inline>
                        <IconArrowBadgeRightFilled style={{ margin: "5px" }} />
                        <Text size="lg" fw={700}>
                            Which sport science or sport nutrition organisations
                            are you currently a member of ?
                        </Text>
                    </Center>
                    <Text size="lg" fw={500} ml={35}>
                        {member.sportScience ? member.sportScience : "N/A"}
                    </Text>
                </Paper>

                <Paper my={5}>
                    <Center inline>
                        <IconArrowBadgeRightFilled style={{ margin: "5px" }} />
                        <Text size="lg" fw={700}>
                            Website:
                        </Text>
                        {member.website ? (
                            <Link
                                to={
                                    member.website?.startsWith("http")
                                        ? member.website
                                        : `https://${member.website}`
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                size="lg"
                                fw={500}
                                style={{ marginLeft: "10px" }}
                            >
                                {member.website}
                            </Link>
                        ) : (
                            <Text size="lg" fw={500} ml={10}>
                                N/A
                            </Text>
                        )}
                    </Center>
                </Paper>
            </Card>
        </Paper>
    );
}
