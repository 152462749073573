import {
    Badge,
    Button,
    Card,
    Image,
    Group,
    Pagination,
    Text,
    useMantineTheme,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import SearchSortFilter from "./sortFilter";
import { useMediaQuery } from "rsuite/esm/useMediaQuery/useMediaQuery";
import { Link, useNavigate } from "react-router-dom";
import { truncateTo100Words } from "../../Helper/TruncateText";
import { IconCalendarMonth, IconCategory } from "@tabler/icons-react";
import moment from "moment";

export default function Blogs() {
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
    const [data, setData] = useState([]);
    const [images, setImages] = useState([]);
    const [page, setPage] = useState(1); // Current page
    const [totalPages, setTotalPages] = useState(1); // Total pages
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("Recent"); // Default sort option
    const [category, setCategory] = useState("All Categories"); // Default category

    useEffect(() => {
        // Reset page to 1 when search, sort, or category changes
        setPage(1); // Set page to 1 immediately
    }, [search, sort, category]);

    useEffect(() => {
        // Fetch blogs whenever page, search, sort, or category changes
        getBlogs(page, search, sort, category);
    }, [page, search, sort, category]);

    const getBlogs = async (
        pageNumber,
        searchQuery = "",
        sortOption = "Recent",
        categoryOption = "All Categories"
    ) => {
        axios
            .get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getallblogs/normal`,
                {
                    params: {
                        page: pageNumber,
                        search: searchQuery,
                        sort: sortOption,
                        category: categoryOption,
                    },
                }
            )
            .then((res) => {
                const { blogs, totalPages } = res.data;
                setData(blogs); // Store the blogs data
                setTotalPages(totalPages); // Store total pages
                blogs.map((blog) => {
                    axios
                        .get(
                            `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${blog.images[0]}`,
                            { responseType: "blob" }
                        )
                        .then((res) => {
                            const img = URL.createObjectURL(res.data);
                            setImages((prev) => ({ ...prev, [blog._id]: img }));
                        })
                        .catch((err) => {
                            console.log(err);
                            notifications.show({
                                title: "Error",
                                message:
                                    err.response?.data?.message ||
                                    "Failed to load images",
                                color: "red",
                                autoClose: 2000,
                            });
                        });
                });
            })
            .catch((err) => {
                console.log(err);
                toast.error(
                    err.response?.data?.message || "Failed to fetch blogs"
                );
            });
    };

    // Function to handle search, sort, and filter changes
    const handleFilterChange = (filterData) => {
        setSearch(filterData.search || ""); // Set search query
        setSort(filterData.sort || "Recent"); // Set sort option
        setCategory(filterData.category || "All Categories"); // Set category filter
        setPage(1); // Reset to page 1 when filters change
    };

    return (
        <div>
            <h1
                style={{
                    fontSize: "1.875rem", // equivalent to text-3xl
                    fontWeight: 600, // equivalent to font-semibold
                    marginTop: "1.25rem", // equivalent to mt-5
                    marginBottom: "1rem", // equivalent to mb-4
                    marginLeft: "2.5rem", // equivalent to mx-10 (left and right margin)
                    marginRight: "2.5rem",
                }}
            >
                Written Resources
            </h1>

            {/* Search, sort, filter functionality */}
            <SearchSortFilter onFilterChange={handleFilterChange} />

            <div
                style={{
                    display: "grid",
                    gridTemplateColumns: isMobile[0]
                        ? "1fr"
                        : "repeat(2, minmax(0, 1fr))",
                    gap: "20px", // Adjust gap between cards
                    marginLeft: isMobile[0] ? "10px" : "2.5rem", // Reduce margin on smaller screens
                    marginRight: isMobile[0] ? "10px" : "2.5rem",
                }}
            >
                {data.map((blog, i) => (
                    <Card
                        key={blog._id}
                        style={{ position: "relative" }}
                        shadow="sm"
                        padding="lg"
                        radius="md"
                        withBorder
                        mx={20}
                        my={20}
                    >
                        <Card.Section>
                            <Image
                                src={images[blog._id]} // Use placeholder if image is not found
                                height={280}
                                alt={blog.title}
                            />
                        </Card.Section>
                        <Group position="apart" mt="md" mb="xs">
                            <Text
                                style={{ textTransform: "capitalize" }}
                                fw={500}
                            >
                                {blog.title}
                            </Text>
                        </Group>
                        <div style={{ display: "flex" }}>
                            <Badge
                                size="md"
                                leftSection={<IconCategory size={18} />}
                                mx={5}
                                color="pink"
                            >
                                {blog.category}
                            </Badge>
                            <Badge
                                size="md"
                                leftSection={<IconCalendarMonth size={18} />}
                                mx={5}
                                color="purple"
                            >
                                {moment(blog.date).format("MMMM Do YYYY")}
                            </Badge>
                        </div>
                        <Text size="sm" color="dimmed">
                            <div
                                style={{
                                    marginBottom: "2.5rem", // equivalent to mb-10
                                }}
                                className="mantine-RichTextEditor-content"
                                dangerouslySetInnerHTML={{
                                    __html: truncateTo100Words(blog.content),
                                }}
                            />
                        </Text>

                        <Link
                            to={`/blog_view/?id=${blog?._id}`}
                            style={{
                                textDecoration: "none",
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "10px",
                            }}
                        >
                            <Button
                                style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    left: "10%",
                                    width: "80%",
                                    margin: "auto",
                                }}
                                autoContrast
                                color="#A3E5E4"
                                mt="md"
                                radius="md"
                            >
                                Read
                            </Button>
                        </Link>
                    </Card>
                ))}
            </div>

            <Pagination
                page={page}
                onChange={setPage}
                total={totalPages}
                color="teal"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                }}
            />
        </div>
    );
}
