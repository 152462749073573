import {
    Card,
    Text,
    Grid,
    Image,
    Box,
    Stack,
    Badge,
    Button,
    Alert,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import classes from "./Dashboard.module.css";
import { getLocalStorage } from "../../Helper/LocalStorage";
import { useEffect, useState } from "react";
import { useMantineTheme } from "@mantine/core";
import img from "./img.jpg";
import "@mantine/carousel/styles.css";
import { useMediaQuery } from "@mantine/hooks";
import axios from "axios";
import toast from "react-hot-toast";
import { truncateTo100Words } from "../../Helper/TruncateText";
import {
    IconCalendarMonth,
    IconCategory,
    IconInfoCircle,
    IconUser,
} from "@tabler/icons-react";
import moment from "moment";
import { Link } from "react-router-dom";

const Dashboard = () => {
    const [newUser, setNewUser] = useState(true);
    const [data, setData] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [images, setImages] = useState({});
    const [eventImages, setEventImages] = useState({});
    const theme = useMantineTheme();
    const isMobile = useMediaQuery("(max-width: 768px)");
    const blogs = [1, 1];
    const [jobs, setJobs] = useState([]);
    const [jobImages, setJobImages] = useState([]);

    useEffect(() => {
        return () => {
            if (false) {
                setNewUser(false);
            }
            document.title = "Dashboard";
            getBlogs();
            getEvents();
            getOpportunities();
        };
    }, []);

    const getBlogs = async () => {
        axios
            .get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/getallblogs/dash`
            )
            .then((res) => {
                setData(res.data);
                res.data.map((blog) => {
                    axios
                        .get(
                            `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${blog.images[0]}`,
                            {
                                responseType: "blob",
                            }
                        )
                        .then((res) => {
                            const img = URL.createObjectURL(res.data);
                            setImages((prev) => ({ ...prev, [blog._id]: img }));
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(
                                err.response?.data?.message ||
                                    "Failed to load images"
                            );
                        });
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getEvents = async () => {
        axios
            .get(`${process.env.REACT_APP_BE_URI}/api/v1/common/events/1`)
            .then((res) => {
                setEventData(res.data);
                res.data.map((item) => {
                    // getting the images would have the common path from BE don't get confused by blogImages
                    axios
                        .get(
                            `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${item.images[0]}`,
                            {
                                responseType: "blob",
                            }
                        )
                        .then((res) => {
                            const img = URL.createObjectURL(res.data);
                            setEventImages((prev) => ({
                                ...prev,
                                [item._id]: img,
                            }));
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(
                                err.response?.data?.message ||
                                    "Failed to load images"
                            );
                        });
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getOpportunities = () => {
        axios
            .get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/opportunities/0`
            )
            .then((res) => {
                setJobs(res.data);
                res.data.map((item) => {
                    // getting the images would have the common path from BE don't get confused by blogImages
                    axios
                        .get(
                            `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${item.images[0]}`,
                            {
                                responseType: "blob",
                            }
                        )
                        .then((res) => {
                            const img = URL.createObjectURL(res.data);
                            setJobImages((prev) => ({
                                ...prev,
                                [item._id]: img,
                            }));
                        })
                        .catch((err) => {
                            console.log(err);
                            toast.error(
                                err.response?.data?.message ||
                                    "Failed to load images"
                            );
                        });
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const carouselSlides = jobs.map((item) => {
        return (
            <Carousel.Slide py={10}>
                <Link
                    to={`/opportunity_view?id=${item._id}`}
                    style={{ textDecoration: "none", width: "100%" }}
                >
                    <Card shadow="lg" radius="lg" className={classes.jobcard}>
                        <Image src={jobImages[item._id]} radius={"lg"} />
                        <div>
                            <Text fw={600} tt={"capitalize"}>
                                {item.title}
                            </Text>
                            <Text>
                                {item.description
                                    .split(" ")
                                    .slice(0, 50)
                                    .join(" ") + "..."}
                            </Text>
                        </div>
                    </Card>
                </Link>
            </Carousel.Slide>
        );
    });

    return (
        <Box p={"40px"}>
            <Text
                fz={50}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Recent Articles
            </Text>
            <Text fw={500} c={"dimmed"} pb={"lg"}>
                Our latest updates and blogs on nutrition, care and much more.{" "}
            </Text>

            {/* UPCOMING EVENTS  */}
            <Text
                fz={35}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Upcoming Events
            </Text>
            <Stack pt={"lg"} pb={"xl"} className={classes.alternate}>
                {eventData.length > 0 ? (
                    eventData.map((item) => {
                        return (
                            <Link
                                to={`/event_view?id=${item._id}`}
                                style={{
                                    textDecoration: "none",
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "10px",
                                    width: "100%",
                                }}
                            >
                                <Card
                                    style={{ cursor: "pointer" }}
                                    w={"100%"}
                                    radius="xl"
                                    shadow="xl"
                                >
                                    <Grid gutter="sm">
                                        <Grid.Col span={isMobile ? 12 : 6}>
                                            <Image
                                                src={eventImages[item?._id]}
                                                radius="xl"
                                                p="sm"
                                            />
                                        </Grid.Col>
                                        <Grid.Col
                                            span={isMobile ? 12 : 6}
                                            py="lg"
                                        >
                                            <Text fz="lg" fw={500}>
                                                {item?.title}
                                            </Text>
                                            <hr
                                                align="left"
                                                className={classes.line}
                                            />
                                            <Text
                                                fz="sm"
                                                tt={"capitalize"}
                                                fw={500}
                                                pb="lg"
                                            >
                                                {moment(item.date).format(
                                                    "MMMM Do YYYY"
                                                )}
                                                <br />
                                                {moment(
                                                    item.time,
                                                    "HH:mm"
                                                ).format("hh:mm A")}
                                                <br />
                                                {item.location || "online"}
                                                <br />
                                                {item.category}
                                            </Text>
                                            <Text fz="sm" fw={400}>
                                                {truncateTo100Words(
                                                    item.content
                                                )}
                                            </Text>
                                        </Grid.Col>
                                    </Grid>
                                </Card>
                            </Link>
                        );
                    })
                ) : (
                    <Alert
                        variant="light"
                        color="green"
                        title="Events"
                        icon={<IconInfoCircle />}
                    >
                        No upcoming events
                    </Alert>
                )}
            </Stack>

            {/* PROFESSIONAL OPPROTUNITIES  */}
            <Text
                fz={35}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Professional Opportunities
            </Text>
            {jobs.length > 0 ? (
                <Carousel
                    withIndicators
                    slideSize={{ base: "100%", sm: "50%", md: "33.333333%" }}
                    slideGap={{ base: 0, sm: "md" }}
                    loop
                    align="start"
                >
                    {carouselSlides}
                </Carousel>
            ) : (
                <Alert
                    variant="light"
                    color="green"
                    title="Professional opportunities"
                    icon={<IconInfoCircle />}
                >
                    Currently, no professional opportunities available
                </Alert>
            )}

            {/* LATEST BLOGS  */}
            <Text
                fz={35}
                fw={700}
                variant="gradient"
                gradient={{
                    from: theme.colors.secondary[0],
                    to: theme.colors.primary[0],
                    deg: 95,
                }}
                display={"inline"}
            >
                Latest Blogs
            </Text>
            <Stack pt={"lg"} pb={"xl"}>
                {data.length > 0 ? (
                    data.map((blog) => {
                        return (
                            <Card radius="xl" shadow="xl">
                                <Grid gutter="sm">
                                    <Grid.Col
                                        my={"auto"}
                                        span={isMobile ? 12 : 6}
                                    >
                                        <Image
                                            src={images[blog?._id]}
                                            radius="xl"
                                            p="sm"
                                        />
                                    </Grid.Col>
                                    <Grid.Col span={isMobile ? 12 : 6} py="lg">
                                        <Text
                                            style={{
                                                textTransform: "capitalize",
                                            }}
                                            fz="lg"
                                            fw={500}
                                        >
                                            {blog?.title}
                                        </Text>
                                        <hr
                                            align="left"
                                            className={classes.line}
                                        />
                                        <div style={{ display: "flex" }}>
                                            <Badge
                                                size="lg"
                                                leftSection={
                                                    <IconCategory size={18} />
                                                }
                                                mx={5}
                                                color="pink"
                                            >
                                                {blog.category}
                                            </Badge>
                                            <Badge
                                                size="lg"
                                                leftSection={
                                                    <IconCalendarMonth
                                                        size={18}
                                                    />
                                                }
                                                mx={5}
                                                color="purple"
                                            >
                                                {moment(blog.date).format(
                                                    "MMMM Do YYYY"
                                                )}
                                            </Badge>
                                        </div>
                                        <Text fz="sm" fw={400}>
                                            <div
                                                style={{
                                                    marginBottom: "2.5rem", // equivalent to mb-10
                                                }}
                                                className="mantine-RichTextEditor-content"
                                                dangerouslySetInnerHTML={{
                                                    __html: truncateTo100Words(
                                                        blog.content
                                                    ),
                                                }}
                                            />
                                        </Text>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <Link
                                                to={`/blog_view/?id=${blog?._id}`}
                                                style={{
                                                    textDecoration: "none",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: "10px",
                                                }}
                                            >
                                                <Button
                                                    style={{
                                                        position: "absolute",
                                                        bottom: `${
                                                            isMobile
                                                                ? "20px"
                                                                : "30px"
                                                        }`,
                                                        width: "45%",
                                                        margin: "auto",
                                                    }}
                                                    autoContrast
                                                    color="#A3E5E4"
                                                    mt="md"
                                                    radius="md"
                                                >
                                                    Read
                                                </Button>
                                            </Link>
                                        </div>
                                    </Grid.Col>
                                </Grid>
                            </Card>
                        );
                    })
                ) : (
                    <Alert
                        variant="light"
                        color="green"
                        title="Blogs"
                        icon={<IconInfoCircle />}
                    >
                        No blogs uploaded
                    </Alert>
                )}
            </Stack>
        </Box>
    );
};
export default Dashboard;
