import { Paper, Table } from "@mantine/core";
import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
export const OrderList = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  const [transactions, setTransactions] = useState([]);
  useEffect(() => {
    getTransactions();
  }, []);
  const getTransactions = async () => {
    await axios
      .post(`${process.env.REACT_APP_BE_URI}/api/v1/common/transactions`, {
        userEmail: user.email,
      })
      .then((response) => {
        setTransactions(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        console.log(error);
      });
  };
  const rows = transactions?.map((element, index) => (
    <Table.Tr key={index}>
      <Table.Td>{index + 1}</Table.Td>
      <Table.Td>{element.customerType}</Table.Td>
      <Table.Td>${element.amount}</Table.Td>
      <Table.Td>{moment(element.startDate).format("MMM Do YYYY")}</Table.Td>
      <Table.Td>{moment(element.endDate).format("MMM Do YYYY")}</Table.Td>
      <Table.Td><a href={element.invoice} target="_blank">click</a></Table.Td>
    </Table.Tr>
  ));

  return (
    <Paper m="lg" mb={'50vh'}>
      <Table>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>S. No.</Table.Th>
            <Table.Th>Subscription Type</Table.Th>
            <Table.Th>Amount</Table.Th>
            <Table.Th>Start Date</Table.Th>
            <Table.Th>End Date</Table.Th>
            <Table.Th>Receipt</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Paper>
  );
};
