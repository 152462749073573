import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Navbar/Sidebar";
import ProfileBar from "../ProfileBar/ProfileBar";
import Footer from "../Navbar/Footer";
import { getLocalStorage, removeLocalStorage } from "../../Helper/LocalStorage";
import { AppShell, Burger, Group } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import axios from "axios";
import { getCookie } from "../../Helper/Cokkies";
import toast from "react-hot-toast";

function HomePage() {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure();
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);
  const navigate = useNavigate();

  useEffect(() => {
    if(window.location.pathname === "/"){
      navigate("/dashboard")
    }
  })

  const toggles = () => {
    return (
      <Group h="100%" px="md">
        <Burger
          opened={mobileOpened}
          onClick={toggleMobile}
          hiddenFrom="sm"
          size="sm"
        />
        <Burger
          opened={desktopOpened}
          onClick={toggleDesktop}
          visibleFrom="sm"
          size="sm"
        />
      </Group>
    );
  };

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 250,
        breakpoint: "sm",
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
      // aside={{
      //   width: 275,
      //   breakpoint: "sm",
      //   collapsed: { mobile: true, desktop: false },
      // }}
      // bg={"var(--mantine-color-gray-1"}
    >
      <AppShell.Header>
        <Navbar toggles={toggles} />
      </AppShell.Header>
      <AppShell.Navbar>
        <Sidebar mobileOpened={mobileOpened} toggleMobile={toggleMobile}  />
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
        <Footer />
      </AppShell.Main>
      {/* <AppShell.Aside>
        <ProfileBar />
      </AppShell.Aside> */}
    </AppShell>
  );
}
export default HomePage;
