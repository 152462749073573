import React, { useEffect } from "react";
import { Avatar, Text, Paper, ScrollArea } from "@mantine/core";
import { getLocalStorage, setLocalStorage } from "../../Helper/LocalStorage";
import { truncateTo100Words } from "../../Helper/TruncateText";
import axios from "axios";
import toast from "react-hot-toast";
import { blobToBase64 } from "../../Helper/BlobToBase64";
import { base64ToBlob } from "../../Helper/Base64ToBlob";

const ProfileBar = () => {
  const user = getLocalStorage("user");
  const profile = getLocalStorage("profilePic");
  const bio = truncateTo100Words(user?.bio);
  const store = getLocalStorage("user");
  const [profileImg, setProfileImg] = React.useState(null);
  useEffect(() => {}, [user, profile, bio]);
  React.useEffect(() => {
    document.title = 'Profile';
    if(!getLocalStorage("profilePic")){
        getProfilePic()
    }else{
        getProfilePicFromLocalStorage()
    }
}, [])
const getProfilePic = async(dataString) => {
    axios.get(`${process.env.REACT_APP_BE_URI}/api/v1/common/getprofilepic/${dataString || store.profile}`, {
        responseType: 'blob'
    }).then((res) => {
        if (res.status === 200) {
          const img = URL.createObjectURL(res.data);
          blobToBase64(res.data).then((base64String) => {
            setLocalStorage("profilePic", base64String)
            setProfileImg(img);
          }).catch((err) => {
            toast.error("Failed to load profile picture, error at blobToBase64");
            console.log(err)
          })
        }
    }).catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
    })
}
const getProfilePicFromLocalStorage = async () => {
  const profilePicData = await base64ToBlob(getLocalStorage("profilePic"));
  setProfileImg(URL.createObjectURL(profilePicData));
  };
  return (
    <Paper
      p="lg"
      radius={0}
      bg="var(--mantine-color-blue-1)"
      component={ScrollArea}
      h={"100%"}
    >
      <Text style={{ textTransform: "capitalize" }} fz={50} fw={700} pl={20}>
        {user?.firstName}
      </Text>
      <Avatar src={profileImg} size={200} radius={15} mx="auto" />
      <Text pl={20} fz="lg" fw={700} pt={25}>
        ABOUT
      </Text>
      <Text pl={20} fz={15} fw={500} pt={10}>
        {bio}
      </Text>
    </Paper>
  );
};

export default ProfileBar;
