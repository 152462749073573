import * as React from "react";
import { removeLocalStorage } from "../../Helper/LocalStorage";
import { Link } from "react-router-dom";
import { Box, UnstyledButton, Image, Flex } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import classes from "./Navbar.module.css";
import logo from "./logo-color.png";

function Navbar(props) {
    const userLogout = (e) => {
        removeLocalStorage("user");
        removeLocalStorage("profilePic");
        window.location.replace("/login");
    };

    return (
        <Box>
            <div className={classes.nav}>
                <div className={classes.controls}>
                    <props.toggles></props.toggles>
                    <Link to="/dashboard">
                        <Image h={40} w={"auto"} src={logo} alt="pines"></Image>
                    </Link>
                </div>
                <div className={classes.controls}>
                    <UnstyledButton
                        className={classes.link}
                        onClick={userLogout}
                    >
                        <Flex
                            align="center"
                            gap="xs"
                            style={{ cursor: "pointer" }}
                        >
                            <p
                                style={{
                                    margin: 0,
                                    color: "var(--mantine-color-blue-8)",
                                    fontWeight: 700, // medium weight for better visibility
                                }}
                            >
                                Log Out
                            </p>
                            <IconLogout color="var(--mantine-color-blue-8)" />
                        </Flex>
                    </UnstyledButton>
                </div>
            </div>
        </Box>
    );
}
export default Navbar;
