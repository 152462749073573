import React, { useEffect, useState } from "react";
import {
    Card,
    Image,
    Text,
    Title,
    Stack,
    Container,
    Chip,
    Badge,
    Button,
    Group,
    Grid,
    Modal,
    Center,
} from "@mantine/core";
import {
    IconCalendar,
    IconCalendarMonth,
    IconCategory,
    IconClock,
    IconCropPortrait,
    IconMapPin,
    IconUser,
} from "@tabler/icons-react";
import axios from "axios";
// import LoaderAnimation from "../..";
import { Carousel } from "react-responsive-carousel";
import { Link, useSearchParams } from "react-router-dom";
import LoaderAnimation from "../loader";
import moment from "moment";
import toast from "react-hot-toast";
import { useDisclosure } from "@mantine/hooks";

const EventView = () => {
    const [opened, { open, close }] = useDisclosure(false);
    const [modalImage, setModalImage] = useState({
        title: "",
        image: "",
    });
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    const [content, setContent] = useState({
        title: "",
        category: "",
        images: "",
        description: "",
        mode: "",
        link: "",
        location: "",
        date: "",
        time: "",
        postDate: "",
        author: "",
    });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!id) return;
        getEvent();
    }, [id]);

    const getEvent = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BE_URI}/api/v1/common/event/${id}`
            );
            const data = response.data;
            let imgData = [];
            //'/api/v1/common/blogimages' is a common route to get the images from the server by passing the img name
            data.images.map(async (item) => {
                const img = await axios.get(
                    `${process.env.REACT_APP_BE_URI}/api/v1/common/blogimages/${item}`,
                    {
                        responseType: "blob",
                    }
                );
                setContent((prev) => ({
                    ...prev,
                    image: [...prev.images, URL.createObjectURL(img.data)],
                }));
                imgData.push(URL.createObjectURL(img.data));
            });
            setContent({
                title: data.title,
                category: data.category,
                images: imgData, // Ensure this is returned by your API
                slideCaptions: data.slideCaptions,
                description: data.content,
                mode: data.mode,
                link: data.link,
                location: data.location,
                date: data.date,
                time: data.time,
                postDate: data.createdAt,
                author: data.author,
                date: data.date,
            });
            setLoading(false); // Set loading to false once data is fetched
        } catch (err) {
            console.log(err);
            toast.error(err.response?.data?.message || "Failed to load data");
            setLoading(false); // Ensure loading is set to false even on error
        }
    };

    if (loading) {
        return <LoaderAnimation />;
    }
    const {
        title,
        category,
        images,
        description,
        mode,
        link,
        location,
        date,
        time,
        postDate,
        author,
        slideCaptions,
    } = content;

    return (
        <>
            {/* IMAGE POPUP MODAL START HERE  */}
            <Modal
                opened={opened}
                onClose={close}
                title={modalImage.title}
                size={"xl"}
            >
                <Image
                    style={{ width: "100%" }}
                    src={modalImage.image}
                    height={300}
                    alt="Event Image"
                    fit="contain"
                />
            </Modal>
            {/* IMAGE POPUP MODAL END HERE  */}

            <Title ml={20} my={20}>
                Event
            </Title>
            <Card p="lg" style={{ maxWidth: 1000, margin: "auto" }}>
                <Card.Section>
                    {images?.length > 0 ? (
                        <div style={{ width: "100%", marginTop: "10px" }}>
                            <Carousel showIndicators={false}>
                                {images?.map((item, index) => (
                                    <div
                                        onClick={() => {
                                            setModalImage({
                                                title: slideCaptions[index],
                                                image: item,
                                            });
                                            open();
                                        }}
                                        key={index}
                                    >
                                        <Image
                                            style={{ width: "100%" }}
                                            src={item}
                                            height={300}
                                            alt="Event Image"
                                            fit="contain"
                                        />
                                        <p>{slideCaptions[index]}</p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    ) : null}
                </Card.Section>

                <Stack spacing="xs" mt="md">
                    <Text size="xl" weight={500}>
                        {title}
                    </Text>

                    <Group spacing="xs">
                        <Badge color="teal" size="lg" variant="light">
                            {category}
                        </Badge>
                    </Group>

                    <Grid gutter="sm">
                        <Grid.Col span={6}>
                            <Group spacing={8}>
                                <IconCalendar size={20} />
                                <Text size="md" fw={700}>
                                    {moment(date).format("MMMM Do YYYY")}
                                </Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            <Group spacing={8}>
                                <IconClock size={20} />
                                <Text size="md" fw={700}>
                                    {time} UTC
                                </Text>
                            </Group>
                        </Grid.Col>
                    </Grid>

                    <Grid gutter="sm">
                        <Grid.Col span={6}>
                            <Group spacing={8}>
                                <IconCropPortrait size={20} />
                                <Text size="md" fw={700} tt={"capitalize"}>
                                    {mode}
                                </Text>
                            </Group>
                        </Grid.Col>
                        <Grid.Col span={6}>
                            {mode === "Offline" ? (
                                <Group spacing={8}>
                                    <IconMapPin size={20} />
                                    <Text
                                        size="sm"
                                        c="dimmed"
                                        tt={"capitalize"}
                                    >
                                        {link || location}
                                    </Text>
                                </Group>
                            ) : null}
                        </Grid.Col>
                    </Grid>

                    <Group>
                        <Text size="md">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: content.description,
                                }}
                            />
                        </Text>
                    </Group>
                    <Link to={link} style={{ textDecoration: "none" }}>
                        <Center>
                            <Button
                                variant="gradient"
                                gradient={{ from: "cyan", to: "lime", deg: 90 }}
                                w={"50%"}
                                mt="md"
                            >
                                {mode === "Online" ? "Join" : "Locate Event"}
                            </Button>
                        </Center>
                    </Link>
                </Stack>
            </Card>
        </>
    );
};

export default EventView;
