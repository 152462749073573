import {
    Text,
    Container,
    ActionIcon,
    Group,
    rem,
    Image,
    Anchor,
} from "@mantine/core";
import {
    IconBrandInstagram,
    IconBrandLinkedin,
    IconBrandX,
    IconBrandFacebook,
    IconMail,
} from "@tabler/icons-react";
import logo from "./logo-color.png";
import classes from "./Footer.module.css";

const data = [
    {
        title: "About",
        links: [
            { label: "Home", link: "https://pines.fruitbowl.tech/" },
            {
                label: "Our Board",
                link: "https://pines.fruitbowl.tech/our-board/",
            },
            {
                label: "Member Profiles",
                link: "https://pines.fruitbowl.tech/member-profiles/",
            },
            {
                label: "Global Connection",
                link: "https://pines.fruitbowl.tech/global-connection/",
            },
            {
                label: "Join Pines",
                link: "https://pines.fruitbowl.tech/join-pines/",
            },
        ],
    },
    {
        title: "Important Links",
        links: [
            {
                label: "Terms & Conditions",
                link: "https://pines.fruitbowl.tech/term-and-conditions/",
            },
            {
                label: "Refund Policy",
                link: "https://pines.fruitbowl.tech/privacy-payment-refund-policy/",
            },
            {
                label: "Membership Categories",
                link: "https://pines.fruitbowl.tech/low-income-membership-categories/",
            },
            {
                label: "Annual Report",
                link: "https://pines.fruitbowl.tech/annual-report/",
            },
        ],
    },
    {
        title: "Community",
        links: [
            {
                label: "Careers",
                link: "https://pines.fruitbowl.tech/career-in-sportsa/",
            },
            { label: "Education", link: "https://pines.fruitbowl.tech/esn/" },
            {
                label: "Scholarships & Awards",
                link: "https://pines.fruitbowl.tech/scholarships-and-awards/",
            },
            {
                label: "Join PINES Listserv",
                link: "mailto:PINESNutrition+subscribe@groups.io",
            },
        ],
    },
];

const socialLinks = [
    {
        icon: (
            <IconMail
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
            />
        ),
        link: "mailto:PINESNutrition+subscribe@groups.io",
    },
    {
        icon: (
            <IconBrandX
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
            />
        ),
        link: "https://x.com/PINESorg",
    },
    {
        icon: (
            <IconBrandLinkedin
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
            />
        ),
        link: "https://www.linkedin.com/company/pines-org/",
    },
    {
        icon: (
            <IconBrandInstagram
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
            />
        ),
        link: "https://www.instagram.com/pinesorg/",
    },
    {
        icon: (
            <IconBrandFacebook
                style={{ width: rem(18), height: rem(18) }}
                stroke={1.5}
            />
        ),
        link: "https://www.facebook.com/PINESNUTRITION",
    },
];

const Footer = () => {
    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <Anchor
                key={index}
                className={classes.link}
                href={link.link}
                target="_self"
                c="black"
                style={{ textDecoration: "none" }}
            >
                {link.label}
            </Anchor>
        ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });

    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <Image h={70} w={"auto"} src={logo} alt="pines"></Image>
                    <Text size="xs" className={classes.description}>
                        PINES is a leading global organization of qualified
                        nutrition and exercise professionals who strive for
                        excellence in sports nutrition in order to achieve
                        optimal support for performance, health and injury
                        prevention for active and athletic individuals
                        worldwide.
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter}>
                <Text size="sm">© 2024 PINES. All rights reserved.</Text>

                <Group
                    gap={0}
                    className={classes.social}
                    justify="flex-end"
                    wrap="nowrap"
                >
                    {socialLinks.map((item) => (
                        <ActionIcon key={item.link} size="lg" variant="subtle">
                            <Anchor
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {item.icon}
                            </Anchor>
                        </ActionIcon>
                    ))}
                </Group>
            </Container>
        </footer>
    );
};

export default Footer;
